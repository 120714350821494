import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  
  date = new Date().getFullYear();
  lblWhatsapp = 'https://api.whatsapp.com/send?phone=556199588641&text=Olá, acabei de visitar o site do Farofina!';

  constructor() { }

  ngOnInit() {
  }

}
